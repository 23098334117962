<template>
  <FocusTrap>
    <div  id="fuel_purchase_view_card" class="card">

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Fuel Purchase List</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="mytable"
                 class="table  table-hover  table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-field="status" data-class="d-none">Status</th>
              <th data-formatter="runningFormatter" data-width="75" >S.No</th>
              <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
              <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
              <th data-field="inv_no" data-width="100" data-sortable="true">Inv No</th>
              <th data-field="inv_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Inv Date</th>
              <th data-field="net_amt" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
              <th data-field="vehicleno" data-width="100" data-sortable="true">Vehicle No</th>
              <th data-field="acc_id" data-class="d-none" data-sortable="true">Acc ID</th>
              <th data-field="remarks" data-sortable="true">Remarks</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

      </div>


      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Invoice</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <vmodal name="fuel_invoice_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @closed="afterClose" >
        <InvoiceForm v-if="invoice.id > 1" v-bind:myvoucher="invoice" v-on:fuel_purchase_saved="loadData" v-on:fuleinvoice_window_closed="closeInvoiceWindow" />
      </vmodal>


      <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="200" @opened="filter_opened" >
        <FocusTrap>
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-transparent" style="padding: 10px;background-color: whitesmoke;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label">From:</label>
                  <div class="col-md-4">
                    <input  id="txtfrom" type="date" class="form-control text-center"  v-model="from_date" :max="upto_date" />
                  </div>

                  <label class="col-md-2 col-form-label">Upto</label>
                  <div class="col-md-4">
                    <input type="date" class="form-control text-center"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>

                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </FocusTrap>
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>
import InvoiceForm from '@/views/xpos/fuelpurchase/FuelPurchaseForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'FuelPurchaseView',
  components: {
    InvoiceForm,
    FabButton
  },
  store,
  data () {
    return {
      mytable: {},
      invoice: JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}'),
      series: 302,
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      purchtype: 0,
      purchfrom: 0,
      branches: [],
      invoice_id: 0,
      selected_ibr: 1,
      ledgers: [],
    }
  },
  created () {
    let user = JSON.parse(localStorage.getItem('xposuser'));
    store.state.user = user;
    store.state.token = localStorage.getItem('xpostoken');

  },
  mounted () {
    const self = this;

    self.$data.from_date = moment().add(-7, 'd').format('YYYY-MM-DD')

    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();
    $('#mytable').on('post-body.bs.table', function (e) {
      $('[data-toggle="popover"]').popover();
    });

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text()
        let status = parseInt($(row.children('*')[1]).text())

        if ($(e.target).text() === 'Modify Invoice') {
          // if (status === 0) {
          self.modifyDocument(id)
          // }
        } else if ($(e.target).text() === 'Cancel') {
          // if(status === 0) {
          self.removeDocument(id, status)
          // }
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#mytable>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });


    self.loadSuppliers();

    $('.context-submenu').contextmenu({
      target: '.dropdown-menu'
    }).on('hidden.bs.context', function () {
      $(this).find('.context-sub-menu .show').removeClass('show')
    })

    self.$modal.show('filter_window');
  },
  methods: {
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    closeModal () {
      this.$modal.hide('fuel_invoice_window');
    },
    beforeOpen () {

    },
    beforeClose () {
      // this.$data.invoice = JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
    },
    filter_opened(){
      $('#txtfrom').focus();
    },
    showModal () {
      this.$modal.show('fuel_invoice_window')
    },
    closeInvoiceWindow(){
      this.$modal.hide('fuel_invoice_window');
    },
    blockModal () {
      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })
    },
    loadSuppliers () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/oilcompany`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.ledgers = resp.data
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      })
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.invoice = JSON.parse('{"id":0,"status":"Active","locked":false,"brn_id":0,"finyear":0,"inv_date":"0001-01-01","doc_no":"","inv_no":"","ledger_id":0,"ledger":{"id":0,"name":"","alias":"","group":{"id":0,"name":"","alias":""},"open_bal":0,"gstin":""},"remarks":"","vehicle_no":"","driver":"","shipment_no":"","delivery_no":"","gross_amt":0,"vat_amt":0,"round_off":0,"net_amt":0,"list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/fuelpurchase/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          self.$data.invoice = resp.data;
          self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
          self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');

          self.$modal.show('fuel_invoice_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    removeDocument (id) {
      const self = this;

      self.$data.invoice.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.invoice)
      }

      swal({
        title: 'What is the reason for cancel this transaction?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          // alert(`${process.env.VUE_APP_ROOT_API}api/xpos/fuelpurchase?remarks=${result.value}`);

          $('#fuel_purchase_view_card').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/fuelpurchase?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('receipt_window');
            if (resp.ok) {
              self.loadData();
              swal({title: 'Canceled!', text: 'Your request has been processed', type: 'success'});
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' });
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' });
          }).finally(()=>{
            $('#fuel_purchase_view_card').unblock();
          });
        }
      })
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('')

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/fuelpurchases?brn_id=${store.state.user.branch.id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
        self.$modal.hide('filter_window');
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
        // $(self.$data.mytable).bootstrapTable('resetView');
      })
    },
  }

}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
